import React, { ReactNode } from "react"

import s from "./two-columns.module.scss"

export interface TwoColumnsProps {
  id?: string
  className?: string
  leftColumn: ReactNode
  leftColumnClassName?: string
  rightColumn: ReactNode
  rightColumnClassName?: string
}
export const TwoColumns = ({
  id,
  className,
  leftColumn,
  leftColumnClassName,
  rightColumn,
  rightColumnClassName,
}: TwoColumnsProps) => (
  <div id={id} className={className}>
    <div className={`page-color ${s.inner}`}>
      <div className={`${s.leftColumn} ${leftColumnClassName}`}>
        {leftColumn}
      </div>
      <div className={`${s.rightColumn} ${rightColumnClassName}`}>
        {rightColumn}
      </div>
    </div>
  </div>
)
