// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable simple-import-sort/imports */
import React from "react"

import { Layout } from "../components/layout"
import { Course } from "../components/course"
import { courseContent } from "../content/course"

const { pageTitle, ...courseProps } = courseContent

export default function CoursePage() {
  return (
    <Layout pageTitle={pageTitle} color="blue">
      <Course {...courseProps} />
    </Layout>
  )
}
