// Do not edit this file! It is generated by prebuild script

import React from "react"

import { CourseContent } from "../content-types"

export const courseContent: CourseContent = {
  pageTitle: "SAP Courses",
  course: {
    title: "SAP Courses: Global Supply Chain",
    subtitle: [
      "Business Global Trade",
      "processes and",
      "integration with SAP GTS",
    ],
    content: [
      "Business flows",
      "System integration (SAP GTS)",
      "Implementation methodology",
      "Organizational set up",
      "Challenges, opportunities, risks",
    ],
    description: (
      <>
        <p>
          This is an opportunity to receive an introduction to the world of SAP
          Logistic and Global trade. The courses are sequential and cover a
          range that starts from the overview of the Global trade processes and
          goes down to the details of the operations and flows in the companies.
        </p>
        <p>
          The approach is multi-dimensional – its parallelly focus on
          understanding and mastering the system set up and logic, learn how the
          business processes of the global supply chain run and last, but not
          least, get familiar with the mechanics of the methodologies and phases
          of the project implementation of an ERP (Enterprise Resource Planning)
          system.
        </p>
        <p>
          As an outcome of this first module of the SAP Global trade courses the
          participants will gain familiarity with the different aspects of the
          system integration of the Global trade and could help:
        </p>
        <ul>
          <li>The business decision makers to drive their implementation</li>
          <li>The users to enhance their knowledge and their efficiency</li>
          <li>The consultants to have better insight</li>
          <li>
            Those who aspire to step in the world of SAP and understand which of
            its dimensions suit them the most
          </li>
        </ul>
      </>
    ),
  },
  schedule: {
    title: "Course agenda",
    sessions: [
      {
        title: "Session 1",
        description: (
          <>
            <p>Introduction to SAP Business Logistics Supply Chain</p>
            <p>
              Procurement – Warehouse – Logistics Execution – Transportation –
              Global trade
            </p>
            <ul>
              <li>Supply chain Organisation and actors</li>
              <li>
                Flows: The objectives of the supply chain manager and its
                constraints
              </li>
              <li>How the supply chain is mapped in ERP</li>
              <li>What is Global Trade</li>
            </ul>
          </>
        ),
      },
      {
        title: "Session 2",
        description: (
          <>
            <p>Introduction to Global Trade (SAP GTS)</p>
            <p>GTS Customs / Preference / Compliance</p>
            <ul>
              <li>Processes and operations covered by SAP GTS</li>
              <li>System structure and main functionalities</li>
              <li>GTS integration with the feeder ERP and 3rd parties.</li>
            </ul>
          </>
        ),
      },
      {
        title: "Session 3",
        description: (
          <>
            <p>Organizational structure</p>
            <p>Master data and Classification</p>
            <ul>
              <li>
                First steps of GTS implementation – Organisational framework
                provided by GTS
              </li>
              <li>
                Customs classification in SAP - HS codes, TARIC, Duties,
                Measures (including external provider data)
              </li>
            </ul>
          </>
        ),
      },
      {
        title: "Session 4",
        description: (
          <>
            <p>GTS Customs: Import and Export integration</p>
            <p>GTS Customs: Special Customs regimes</p>
            <ul>
              <li>
                Import flows and integration with feeder system: Clearance at
                Border / at Warehouse, Transit T1 – options offered by SAP GTS
              </li>
              <li>Export flow and integration with feeder system: S4 vs TM</li>
              <li>Special Customs regimes: Bonded warehouse, IPR, OPR</li>
              <li>Electronic communication with Customs Authorities</li>
            </ul>
          </>
        ),
      },
      {
        title: "Session 5",
        description: (
          <>
            <p>
              GTS Preference: Long term vendor declaration and Preference
              determination
            </p>
            <ul>
              <li>
                How to manage the Preference determination with SAP GTS: process
                flow, preference determination, integration with feeder
              </li>
              <li>
                Specific topics: Cross company preference status transfer,
                Min/Max determination, preference text on the invoice
              </li>
            </ul>
          </>
        ),
      },
      {
        title: "Session 6",
        description: (
          <>
            <p>GTS Compliance: License control</p>
            <ul>
              <li>Thorough explanation of the set up of License control</li>
              <li>
                Several Business cases for License control based on national,
                regulation (EAR, Dual-use, Narcotics) and Goods Distribution
                Practice (GDP)
              </li>
            </ul>
          </>
        ),
      },
    ],
    details: [
      {
        title: "Place:",
        description: ["104 Rue Stassart 1050", "Brussels, Belgium"],
      },
      {
        title: "Attendance:",
        description: ["On premise and online"],
      },
    ],
  },
  signUpEmail: {
    address: "info@wisdomtreasury.com",
    subject: "Sign up to the course",
  },
  getInTouchSlogan: ["For more information"],
}
