import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { ArrowDown } from "../arrow-down"
import { ArrowRight } from "../arrow-right"
import { GetInTouch } from "../get-in-touch"
import { ImageBackground } from "../image-background"
import { Link } from "../link"
import { MultilineText } from "../multiline-text"
import { TwoColumns } from "../two-columns.tsx"
import s from "./course.module.scss"
import { CourseDataProps } from "./dataProps"

export interface CourseProps extends CourseDataProps {}

const COURSE_DESCRIPTION_ID = "course-description"

export const Course = ({
  course: {
    title: courseTitle,
    subtitle: courseSubtitle,
    content: courseContent,
    description: courseDescription,
  },
  signUpEmail: { address, subject },
  schedule: { title: scheduleTitle, sessions, details },
  getInTouchSlogan,
}: CourseProps) => (
  <>
    <ImageBackground outerClassName={s.mainImage}>
      <StaticImage
        placeholder="none"
        layout="fluid"
        maxWidth={5120}
        src="img/course-main.png"
        alt="Learn and Succeed"
        transformOptions={{}}
        blurredOptions={{}}
      />
      <Link id={s.mainImageLink} refId={COURSE_DESCRIPTION_ID} />
    </ImageBackground>
    <div id={s.course}>
      <h1 id={s.courseTitle}>{courseTitle}</h1>
      <TwoColumns
        id={s.courseDescription}
        leftColumnClassName={s.courseLeftColumn}
        leftColumn={
          <>
            <div id={COURSE_DESCRIPTION_ID} className={s.anchor} />
            <div className={s.header}>
              <h1>Course</h1>
              <h2>
                <MultilineText text={courseSubtitle} />
              </h2>
            </div>
            <h3>Content:</h3>
            <ul>
              {courseContent.map((c, i) => (
                <li key={i}>{c}</li>
              ))}
            </ul>
          </>
        }
        rightColumn={<div id={s.courseRightColumn}>{courseDescription}</div>}
      />
    </div>

    <ImageBackground>
      <div id={s.topTriangles}>
        <div>
          <div className="page-color">
            Schedule
            <ArrowDown />
          </div>
        </div>
        <svg
          version="1.1"
          viewBox="0 -30 5120 3312.1"
          className={s.topTriangles}
        >
          <filter id="shadow-white">
            <feGaussianBlur stdDeviation="19"></feGaussianBlur>
          </filter>
          <filter id="shadow-blue">
            <feGaussianBlur stdDeviation="30"></feGaussianBlur>
          </filter>
          <path className="gray" d="M0-30h5120v292.6l-1573.9 908.5L0 0z" />
          <path
            className="shadow"
            filter="url(#shadow-white)"
            d="M0 0v3218.1l3546.1-2047z"
          />
          <path className="white" d="M0 0v3218.1l3546.1-2047z" />
          <path
            className="shadow"
            filter="url(#shadow-blue)"
            d="M5120 262.6L0 3218.1h5120z"
          />
          <path d="M5120 262.6L0 3218.1v64h5120z" />
        </svg>
      </div>
    </ImageBackground>
    <div id={s.schedule}>
      <div>
        <h1>{scheduleTitle}</h1>
        <ul id={s.events}>
          {sessions.map((session, i) => (
            <li key={i}>
              <h2>{session.title}</h2>
              <div className={s.content}>{session.description}</div>
            </li>
          ))}
        </ul>
        <hr />
        <div id={s.details}>
          {details.map((detail, i) => (
            <div key={i}>
              <div className={s.detailTitle}>{detail.title}</div>
              <div className={s.detailDescription}>
                <MultilineText text={detail.description} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div id={s.bottomTriangles}>
      <svg viewBox="0 0 5120 3000">
        <filter id="shadow-blue">
          <feGaussianBlur stdDeviation="30"></feGaussianBlur>
        </filter>
        <path
          className="shadow"
          filter="url(#shadow-blue)"
          d="M5120 0H0v2955.6z"
        />
        <path d="M5120 0H0v2955.6z" />
      </svg>
    </div>
    <div id={s.signUp}>
      <div>
        <a className="page-color" href={`mailto:${address}?subject=${subject}`}>
          <ArrowRight /> Sign Up
        </a>
      </div>
    </div>
    <GetInTouch slogan={getInTouchSlogan} />
  </>
)
