import React, { SVGProps } from "react"

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 22" {...props}>
    <path
      d="M1,1,11,11,1,21"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)
